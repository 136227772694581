<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :before-close="cancel" :visible.sync="dialog" title="新增限制" width="500px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="限制次数" prop="buyTimes">        
        <el-input-number v-model="form.buyTimes" :min="0" :max="99999"  style="width: 140px"/>
      </el-form-item>
      <div class="h">
      <el-form-item label="开始日期">
        <!-- <el-date-picker v-model="form.beginAt" value-format="timestamp" placeholder="开始日期" default-time="00:00:00" style="width: 140px"/> -->
        <el-date-picker
              v-model="form.times"
              type = "daterange"
              value-format="timestamp"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 290px"
            />
                        <div class="padding-0-05 fs-mini el-icon-warning fc-e">&nbsp;未设置起止日期时,则表示长期有效</div>

      </el-form-item>
      <!-- <el-form-item label="结束日期">
        <el-date-picker v-model="form.expireAt" value-format="timestamp" placeholder="结束日期" default-time="23:59:59" style="width: 140px"/>
      </el-form-item> -->
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      loading: false, 
      dialog: false,
      form: {
        buyTimes: null,
        beginAt: null,
        expireAt: null
      },
      rules: {
        buyTimes: [
          { required: true, message: '请选择限制次数', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.doAdd();
        }
      })
    },
    doAdd() {
      let data = JSON.parse(JSON.stringify(this.form));
      if(data.times!=null && data.times.length>1){
          data.beginAt = data.times[0];
          data.expireAt = data.times[1];
        }
      request({
          url: "api/performanceBuyLimit/expire",
          method: "post",
          data: data,
        }).then((res) => {            
            this.$notify({
              title: "保存成功",
              type: "success",
              duration: 2500,
            });
            this.loading = false;          
            this.$parent.loadOder();
            this.dialog = false;
          })
          .finally((_) => {
            this.loading = false;
          });      
    },    
    resetForm() {
      this.dialog = false;      
      this.form = {
        buyTimes: 1,
        beginAt: null,
        expireAt:null,
      }
    }
  }
}
</script>

